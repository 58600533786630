import moment from "moment";
import mitt from 'mitt'

import { TabName, Preview3D } from "@frontend/js/types/order-types";
import { BusEventConfig, PaginationData } from "@frontend/js/types/shared-types";

export const omsBUS = mitt<BusEventConfig>();
(window as any).omsBUS = omsBUS;

/**
 * Frontend UID must only used for FE level object identifing or mapping such as using key in v-for.
 * The UID using a global counter in the window object to ensure uniqueness.
 *
 * @returns {string} A unique id string.
 */
export function feUID() {
    const win = (window as any)
    if (!win.uuidCounter) {
        win.uuidCounter = 0;
    }
    win.uuidCounter++;
    function getRandomHexSegment() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    const counterHex = win.uuidCounter.toString(16).padStart(8, '0');

    return `${getRandomHexSegment()}-${getRandomHexSegment()}-${counterHex}`;
}

export function waitDelay(delay: number = 400) {
    return new Promise<void>((res) => {
        setTimeout(() => {
            res();
        }, delay);
    });
}

waitDelay.BINDING_DELAY = 100;
waitDelay.EVENT_DELAY = 200;
waitDelay.TRANSITION_DELAY = 400;
waitDelay.DEBOUNCE_DELAY = 600;
waitDelay.LOADER_DELAY_MIN = 800;
waitDelay.EXTENDED_DEBOUNCE_DELAY = 1000;

export function toFormattedDateText(dateString, resultFormat = 'DD MMM YYYY') {
    const mDate = moment(dateString);
    if (mDate.isValid()) {
        return mDate.format(resultFormat)
    } else {
        return '';
    }
}

export function cleanDateValue(dateString, setting: {
    givenFormat?: string,
    returnFormat?: string,
    returnFallback?: any,
} = {}) {
    setting.givenFormat = setting.givenFormat || 'YYYY-MM-DD';
    setting.returnFormat = setting.returnFormat || 'YYYY-MM-DD';
    setting.returnFallback = setting.returnFallback === undefined ? '' : setting.returnFallback;

    const mDate = moment(dateString, setting.givenFormat);
    if (mDate.isValid()) {
        return mDate.format(setting.returnFormat)
    } else {
        return setting.returnFallback;
    }
}


export function getUrlParam(key: string) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    return params.get(key);
}

export function setUrlParam(key: string, value: string, clear: boolean = false) {
    const url = new URL(window.location.href);
    let search = clear ? '?' : url.search;
    const params = new URLSearchParams(search);
    params.set(key, value);
    window.history.replaceState({}, '', `${url.pathname}?${params.toString()}`);
}

export function isValidTabName(tab: string): tab is TabName {
    return Object.keys(TabName).includes(tab);
}

export function removeUrlParam(key: string) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete(key)
    window.history.replaceState({}, '', `${url.pathname}?${params.toString()}`);
}

export function toTitleCase(str: string) {
    return (str || '')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

/**
 *
 * This function is used to sanitize a value that is supposed to be either null or a number.
 * It will accept any type and return the relevant value, which will be either a number or null.
 *
 * @returns {number|null}
 */
export function cleanIntegerORNullValue(val: any): number | null {
    if (val === null) {
        return null;
    }
    const parsedVal = parseInt(val)
    return (Number.isInteger(parsedVal) ? parsedVal : null);
}

export function removeDuplicatesByProperty<T>(array: T[], property: string | number) {
    const seen = new Set<string | number>();
    return array.filter(item => {
        const value = item[property];
        if (seen.has(value)) {
            return false;
        }
        seen.add(value);
        return true;
    });
}

export function preparePaginationData(response: any): PaginationData {
    const pagination = {} as PaginationData;
    pagination.current = response?.current_page || 1;
    pagination.perPage = response?.per_page || 10;
    pagination.totalItem = response?.total || 0;
    pagination.totalPage = response?.last_page || 1;
    pagination.displayItemStart = response?.from || 0;
    pagination.displayItemEnd = response?.to || 0;
    return pagination;
}

export function getEditOrderIdFromURL(): number | null {
    const url = window.location.pathname;
    const editModeRegex = /^\/orders\/overview\/(\d+)\/edit$/;
    const editMatch = url.match(editModeRegex);
    if (editMatch && editMatch[1]) {
        return parseInt(editMatch[1], 10) || null;
    } else {
        return null;
    }
}
export function getOverviewPageUrl(type:'ORDER'|'SERVICE_ORDER'='ORDER'){
    return type==='ORDER'?'/orders/overview':'/orders/service-overview'
}

export function getOrderEditPageUrl(orderId: number) {
    return `/orders/overview/${orderId}/edit`
}

export function processPreview3D(template: any): Preview3D {
    return {
        id: template.template_id || template.id, // template_id return in 3d preview API, id on order fetch
        filePath: template.file_path,
        fileName: template.file_name,
        isPreviewGenerated: !!template.is_pdf_generated
    }
}

export function updatePageHeading(pageHeading = '') {
    pageHeading = pageHeading.trim();
    const appName = import.meta.env.VITE_APP_NAME;
    document.title = pageHeading ? appName + ' | ' + pageHeading : appName;
    const pageTitle = document.querySelector('[data-el-id="page-title"]');
    if(pageTitle){
        pageTitle.textContent =pageHeading;
    }
}
export function  updateBreadcrumb(payload:{name:string, url: string}){
    const bcItem = document.querySelector("#page_toolbar li.breadcrumb-item:nth-child(2) > a");
    if(bcItem){
        bcItem.textContent = payload.name;
        bcItem.setAttribute('href', payload.url);
    }
}
