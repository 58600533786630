
import _ from 'lodash';
import {initMenuSearch} from './menu-search'
function OMSbootstrap() {
    if (document.readyState === 'interactive' || document.readyState === 'complete' ) {
        init();
        if (
            localStorage.getItem('navoff') === 'true' ||
            window.innerWidth <= deviceWidthMap.desktop
        ) {
            asideAttach.togglerEl?.classList?.add('toggler-enable');
        }
        InitAsideTogglerPositioning();
        initMenuSearch();
    } else {
        document.removeEventListener('readystatechange', OMSbootstrap);
        document.addEventListener('readystatechange', OMSbootstrap);
    }
}
const deviceWidthMap = {
    mobile: 480,
    tablet: 768,
    desktop: 1024,
    largeDesktop: 1200,
};

const asideAttach = {
    asideEl: null,
    asideLogoEl: null,
    togglerEl: null,
    toggleButton: null,
}

const broadcast = _.debounce((payload) => {
    document.dispatchEvent(new CustomEvent('asideAttachPosChanged', payload))
}, 50, {leading:true});

function init() {
    asideAttach.asideEl = document.querySelector('[data-el-id="aside-el"]');
    asideAttach.asideLogoEl = document.querySelector(
        '[data-el-id="aside-el"] .aside-logo'
    );
    asideAttach.togglerEl = document.querySelector(
        '[data-el-id="aside-toggler"]'
    );
    asideAttach.toggleButton = document.querySelector(
        '[data-el-id="aside-toggler"] .btn'
    );
    checkIfHidden();
}
OMSbootstrap();


function InitAsideTogglerPositioning() {
    const elMutationObserver = () => {
        attachAsideToggler('el');
    };

    const pageResizeListener = () => {
        attachAsideToggler('page');
    };

    const elResizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries)
            if (entry.target === asideAttach.asideEl) {
                elMutationObserver();
            }
    });

    if (asideAttach?.asideEl) {
        elResizeObserver.observe(asideAttach.asideEl);
    }

    window.addEventListener('resize', pageResizeListener);

    let clearTm = 0;

    asideAttach?.asideEl?.addEventListener('mouseenter', function () {
        clearTimeout(clearTm);
        if (!isClosed()) {
            asideAttach.togglerEl.classList.add('toggler-enable');
        } else {
            clearTm = setTimeout(() => {
                asideAttach.togglerEl.classList.remove('toggler-enable');
            }, 200);
        }
    });
    asideAttach?.asideEl?.addEventListener('mouseleave', function () {
        clearTimeout(clearTm);
        if (!isClosed()) {
            clearTm = setTimeout(() => {
                asideAttach.togglerEl.classList.remove('toggler-enable');
            }, 200);
        } else {
            asideAttach.togglerEl.classList.add('toggler-enable');
        }
    });

    asideAttach?.togglerEl?.addEventListener('mouseenter', function (event) {
        event.stopPropagation();
        clearTimeout(clearTm);
        asideAttach.togglerEl.classList.add('toggler-enable');
    });
    asideAttach?.toggleButton?.addEventListener('click', function (event) {
        if (isClosed()) {
            localStorage.setItem('navoff', 'true');
        } else {
            localStorage.removeItem('navoff');
        }
    });
}

function isClosed() {
    return asideAttach.toggleButton?.classList?.contains('active');
}

function checkIfHidden() {
    if (localStorage.getItem('navoff') === 'true' || (window.innerWidth <= deviceWidthMap.desktop)) {
        document.body.setAttribute('data-kt-aside-minimize', 'on');
        // asideAttach.togglerEl.classList.add('toggler-enable')
        asideAttach.toggleButton?.classList?.add('active')
        attachAsideToggler();
    }
}

function attachAsideToggler() {
    if (asideAttach.asideLogoEl) {
        const rect = asideAttach.asideLogoEl.getBoundingClientRect();
        const top = (rect.bottom - 50);
        const left = (rect.right - 17);
        asideAttach.togglerEl.style.top = `${top}px`;
        asideAttach.togglerEl.style.left = `${left}px`;
        broadcast({ top, left })
    }
}


/**
 * The dom content loaded state.
 */
document.addEventListener('DOMContentLoaded', function () {
    showFlashMessages();
    initLangSwitcher();
    initGlobalSearchForms();
});

/**
 * Shows the flash messages if available in the input
 */
function showFlashMessages() {
    if (document.getElementById('flash_success_message')?.value) {
        toastr.success(document.getElementById('flash_success_message').value);
    }
    if (document.getElementById('flash_error_message')?.value) {
        toastr.error(document.getElementById('flash_error_message').value);
    }
}

function initLangSwitcher() {
    const optionFormat = function(item) {
        if ( !item.id ) {
            return item.text;
        }

        const span = document.createElement('span');
        const imgUrl = item.element.getAttribute('data-kt-select2-country');
        let template = '';

        template += '<img src="' + imgUrl + '" class="rounded-circle h-20px"/>';

        span.innerHTML = template;

        return $(span);
    }

    $('#language-switcher').select2({
        templateSelection: optionFormat,
        templateResult: optionFormat,
        minimumResultsForSearch: -1,
    });
}

/**
 * Initializes global search functionality for forms.
 * This function targets all forms with class 'search-form' or id containing 'Search'
 * and adds an event listener to submit the form when Enter key is pressed.
 */
function initGlobalSearchForms() {
    // Target all forms with class 'search-form' or id containing 'Search'
    const searchForms = document.querySelectorAll('form[id*="Search"], form.search-form');

    searchForms.forEach(form => {
        form.addEventListener('keydown', function(event) {
            if (event.key === 'Enter') {
                event.preventDefault();
                form.submit();
            }
        });
    });
}
