import { debounce } from 'lodash';
import { waitDelay } from './utils/shared';
import { Menu } from './types/shared-types';

let searchBoxEl: HTMLInputElement | null = null;
let clearButtonEl: HTMLElement | null = null;
let asideEl: HTMLElement | null = null;
let originalMenuList: HTMLElement | null = null;
let resultMenuList: HTMLElement | null = null;
let resultMenuContainer: HTMLElement | null = null;
let noDataMessageEl: HTMLElement | null = null;
let menuItemMap: Menu[] = [];

function parseMenu(menuElement: Element, fromSub = false, parentText: string[]): Menu[] {
    const menuItems: Menu[] = [];
    let listItems: NodeListOf<Element> | null = null;
    if (fromSub) {
        listItems = menuElement.querySelectorAll(':scope > .menu-sub .menu-item');
    } else {
        listItems = menuElement.querySelectorAll(':scope > .menu-item');
    }

    (listItems || []).forEach((li) => {
        const anchor = li.querySelector(':scope > .menu-link') as HTMLElement;
        if (anchor) {
            const titleElement = anchor.querySelector('.menu-title') as HTMLElement;
            if (titleElement) {
                const menuTitle = titleElement?.textContent || ''
                const newParentTextArr = [...parentText];
                newParentTextArr.push(menuTitle);
                const menuItem: Menu = {
                    menuTitle,
                    titleElement,
                    parentText,
                    element: anchor,
                    children: parseMenu(li, true, newParentTextArr),
                };
                menuItems.push(menuItem);
            }
        }
    });
    return menuItems;
}

function getElements() {
    if (!searchBoxEl) {
        searchBoxEl = document.querySelector('#search-box [data-id="search-input"]');
    }
    if (!clearButtonEl) {
        clearButtonEl = document.querySelector('#search-box [data-id="clear-search-input"]');
    }
    if (!asideEl) {
        asideEl = document.querySelector('[data-el-id="aside-el"]');
    }

    if (!originalMenuList) {
        originalMenuList = document.querySelector('[data-el-id="aside-el"] [data-el-id="menu-list"]');
    }

    if (!resultMenuContainer) {
        resultMenuContainer = document.querySelector('[data-el-id="aside-el"] [data-el-id="menu-search-result-container"]');
    }

    if (!noDataMessageEl) {
        noDataMessageEl = document.querySelector('[data-el-id="aside-el"] [data-el-id="no-data-template"]');
    }

    return {
        searchBoxEl,
        clearButtonEl,
        asideEl,
        originalMenuList,
        resultMenuContainer,
        noDataMessageEl
    };
}

function isElValid(element: HTMLElement | null): element is HTMLElement {
    return element instanceof HTMLElement;
}

function searchMenu(menuItems: Menu[], searchText: string): Menu[] {
    const result: Menu[] = [];
    menuItems.forEach(menuItem => {
        if (menuItem.menuTitle.toLowerCase().includes(searchText.toLowerCase())) {
            result.push(menuItem);
        }
        const childResults = searchMenu(menuItem.children, searchText);
        result.push(...childResults);

    });
    return result;
}

function onSearchChange() {
    const { searchBoxEl, asideEl, resultMenuContainer, noDataMessageEl } = getElements();
    if (
        isElValid(searchBoxEl) &&
        isElValid(asideEl) &&
        isElValid(resultMenuContainer) &&
        isElValid(noDataMessageEl)
    ) {
        resultMenuContainer.innerHTML = '';
        if (!searchBoxEl.value.length) {
            asideEl.classList.remove('menu-search-on');
        } else {
            asideEl.classList.add('menu-search-on');
            prepareMenuItem();
            const result = searchMenu(menuItemMap, searchBoxEl.value);
            if (result.length === 0) {
                noDataMessageEl.classList.add('show-no-result');
            } else {
                noDataMessageEl.classList.remove('show-no-result');
                if (!resultMenuList) {
                    throw new Error('resultMenuList not found');
                }
                resultMenuContainer.appendChild(resultMenuList);
                result.forEach(menuItem => {
                    highlightText(menuItem.titleElement, searchBoxEl.value);
                    displayAllMenuParent(menuItem.element);
                    openAllParentAccordion(menuItem.element);
                });
            }
        }
    }
}

function displayAllMenuParent(element: HTMLElement | null) {
    let parent = element;
    while (parent) {
        parent.classList.add('show-result-item');
        parent = parent?.parentElement?.closest('.menu-item,.menu') || null;
    }
    const parentMenuItem = element?.closest('.menu-item');
    if (parentMenuItem) {
        parentMenuItem.querySelectorAll('.menu-item')?.forEach((el) => {
            el.classList.add('show-result-item');
        });
        parentMenuItem.querySelectorAll('.menu-arrow').forEach((el) => {
            const menuHavingArrow = el.closest('.menu-item');
            if (menuHavingArrow) {
                menuHavingArrow.removeEventListener('click', arrowToggle);
                menuHavingArrow.addEventListener('click', arrowToggle);
            }
        });
    }
}

function arrowToggle(event) {
    const el = event.currentElement || event.target;
    const parent = el.closest('.menu-item');
    if (parent) {
        parent.classList.toggle('show');
    }
}

function openAllParentAccordion(element: HTMLElement) {
    let parent: Element | null = element.closest('.menu-sub-accordion,menu-accordion');
    while (parent) {
        parent.classList.add('show', 'hover', 'show-result-item');
        parent = parent?.parentElement?.closest('.menu-sub-accordion,.menu-accordion') || null;
    }
}

function highlightText(element: HTMLElement, text: string) {
    const innerHTML = element.innerHTML;
    const index = innerHTML.toLowerCase().indexOf(text.toLowerCase());
    const prefix = innerHTML.substring(0, index);
    const markedContent = innerHTML.substring(index, index + text.length);
    const postfix = innerHTML.substring(index + text.length);
    if (index >= 0) {
        element.innerHTML = `${prefix}<span class="search-highlight">${markedContent}</span>${postfix}`
    }
}

function onClearButtonClick() {
    const { searchBoxEl } = getElements();
    if (isElValid(searchBoxEl)) {
        searchBoxEl.value = '';
    }
    onSearchChange();
}

function prepareMenuItem() {
    const { originalMenuList } = getElements();
    if (isElValid(originalMenuList)) {
        resultMenuList = originalMenuList.cloneNode(true) as HTMLElement;
        resultMenuList.id = 'result-menu-list';
        resultMenuList.style.height = getResultBoxHeight();
        resultMenuList.querySelectorAll('.hover,.show,.active').forEach(el => {
            el.classList.remove('hover', 'show', 'active');
        });
        menuItemMap = parseMenu(resultMenuList as HTMLElement, false, []);
    }
}

function getResultBoxHeight() {
    const availableHight = (document.getElementById('kt_aside')?.getBoundingClientRect()?.height) || 0;
    let occupiedHeight = 0;
    document.querySelectorAll('#kt_aside_logo, #search-box, #kt_aside_footer').forEach(el => {
        const rect = el.getBoundingClientRect();
        occupiedHeight += (rect?.height || 0);
    });
    const resultBoxHeight = availableHight - occupiedHeight;
    if (resultBoxHeight > 0) {
        return `${resultBoxHeight}px`;
    } else {
        return '';
    }
}

function pageResizeListener() {
    const resultMenuList = document.getElementById('result-menu-list');
    if (resultMenuList) {
        resultMenuList.style.height = getResultBoxHeight();
    }
}

export function initMenuSearch() {
    const { searchBoxEl, clearButtonEl } = getElements();
    if (isElValid(searchBoxEl) && isElValid(clearButtonEl)) {
        searchBoxEl.addEventListener('input', debounce(onSearchChange, waitDelay.EVENT_DELAY));
        clearButtonEl.addEventListener('click', onClearButtonClick);
        searchBoxEl.addEventListener('drop', function (event) {
            event.preventDefault();
        });
        window.addEventListener('resize', debounce(pageResizeListener, waitDelay.EVENT_DELAY));
    }
}
