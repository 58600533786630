export enum OrderView {
    ADD_EDIT_ORDER = 'ADD_EDIT_ORDER',
    MAIL = 'MAIL',
    HISTORY = 'HISTORY'
}

export enum TabName {
    general = 'general',
    details = 'details',
    delivery = 'delivery',
    addons = 'addons',
    data = 'data',
    shipping = 'shipping',
    shippingAddress = 'shippingAddress',
    invoiceAddress = 'invoiceAddress',
    payment = 'payment',
    claim = 'claim',
}

export enum AddOrderConfig {
    // FIXME values copied from previously existed obj const variable
    DEFAULT_ORDER_TYPE = 1,
    PRINTING_TECHNOLOGY_DIGITAL_CALL_ID = 3,
    PRODUCT_CATEGORY_PAPER_ID = 2
}

export enum AddressType {
    INVOICE = 1,
    DELIVERY = 2,
    SENDER = 3,
    DELIVERY_NOTE = 4,
}

export interface tabItemState {
    disabled: boolean,
    show: boolean,
    hasPermission: boolean,
    name: TabName,
    error: boolean,
    displayName: string
};
export type OrderTabState = {
    [key in TabName]: tabItemState
}

export enum OrderType {
    PRINT_ORDER = 1,
    SERVICE_ORDER = 2,
    SELLING_ORDER = 3
}


export enum PaperFoldingInfos {
    Cutting = 1,
    Folding = 2,
    Finishing = 3
}

export enum ProductCategory {
    BAGS = 1,
    PAPER = 2,
    PVC_CARDS = 3
}

export enum SupplierCategories {
    INHOUSE_SUPPLIER = 1,
    EXTERNAL_SUPPLIER = 2,
    PAPER_BAG_SUPPLIER = 3,
    MACHINE_SUPPLIER = 4,
    TOOL_SUPPLIER = 5,
    COMMODITY_SUPPLIER = 6,
}

export interface DropdownItem {
    id: string;
    text: string;
}
export interface searchListItem extends DropdownItem {
    quote_pdf?: string;
    isNewCustomerLink?: boolean;
}

export enum GeneratedDataFile {
    zipFile = 'zipFile',
    orderDataFile = 'orderDataFile',
    paperPlateDesignFile = 'paperPlateDesignFile',
    cuttingTemplateFile = 'cuttingTemplateFile'
}
export interface GeneratedFileItem {
    displayName: string,
    show: boolean,
}
export interface ClaimImage {
    claim_image: string,
    claim_image_path: string,
    id: number | string
    order_claim_id: number
}

export enum OrderUrlParam {
    tab = 'tab',
    openActionProfile = 'open-action-profile',
}
export interface MailArchive {
    id: number,
    dateString: string,
    from: string,
    folder: string,
    subject: string,
    body: string,
    isRead: boolean
    isOpen: boolean,
    attachments: MailAttachment[];
}

export interface MailAttachment {
    id: number;
    name: string;
    path: string;
    content_type: string;
    size: number;
}

export interface OrderHistory {
    feId: string,
    auditId: number,
    userName: string,
    userEmail: string,
    pageName: string,
    fieldName: string,
    tabname: string,
    dateString: string,
    timeString: string,
    fromText: string,
    toText: string,
    model: string,
}

export interface dataFilePickerItem {
    showPicker: boolean,
    file: null | string,
    displayName: string,
    downloadUrl: string,
    downloadUrlFieldName: string,
    fileType: string,
    isUploading: boolean,
};

export enum dataFilePickerSection {
    printFile_1 = 'printFile_1',
    printFile_2 = 'printFile_2',
    exampleFile = 'exampleFile',
    addonDataFile = 'addonDataFile',
    extraFile = 'extraFile'
};


export interface Preview3D {
    id: string,
    filePath: string
    fileName: string,
    isPreviewGenerated: boolean,
}

export interface ProofFile {
    filePath: string
    fileName: string,
    isProofFileGenerated: boolean,
}

export enum PrintingTechnology {
    PVC_CARDS_DIGITAL = 1,
    PVC_CARDS_OFFSET = 2,
    PVC_CARDS_DIGITAL_CALL = 3,
    PAPER_DIGITAL_KM_1 = 4,
    BAGS_OFFSET = 5
}

export enum ThumbnailStatus {
    INIT = 'INIT',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}
export interface ThumbnailImage {
    url: string,
    status: ThumbnailStatus
};

export interface ProcessStepInfo {
    id: number,
    name: string,
}

export enum OrderPaymentFlagType {
    INVOICE_FLAG = 'INVOICE_FLAG',
    PAID_FLAG = 'PAID_FLAG',
}
export enum OrderPaymentTypes {
    // Move to const variable binding from BE
    VORKASSE = 1,
    RECHNUNG = 2,
    NACHNAHME = 3,
    PAYPAL = 4,
    SOFORT_BANKING = 5,
    LASTSCHRIFT = 6,
    RECHNUNG_60_TAGE = 7,
}

export interface PartialDeliveryData {
    uid: string;
    deliveryDate: string;
    deliveredAmount: number;
    trackingId: string;
    shippingService: string;
    shippingWeight: string;
    numberOfPackages: number;
    deliveryCompany: string;
}

